<template>
	<div class="amap-page-container">
		<el-amap-search-box v-if="isShowSearch" id="search" :search-option="searchOption" :on-search-result="onSearchResult" />
		<el-amap
			vid="amapDemo"
			:center="center"
			:amap-manager="amapManager"
			:zoom="zoom"
			:events="events"
			class="amap"
			ref="map"
			:style="{ height: mapHeight + 'px' }"
		>
			<el-amap-marker
				v-for="marker in markers"
				:position="marker.position"
				:key="marker.id"
				:offset="marker.offset"
				:events="marker.events"
				:icon="marker.icon"
				ref="marker"
				vid="component-marker"
			></el-amap-marker>
			<el-amap-polyline
				:visible="isShowPolyline"
				:path="polyline.path"
				strokeColor="#48C083"
				lineJoin="round"
				strokeStyle="solid"
				strokeWeight="4"
			></el-amap-polyline>
			<!-- 位置名称显示 -->
			<template v-if="isShowText">
				<el-amap-text
					v-for="(marker, index) in markers"
					:key="'text' + index"
					:text="marker.text"
					:offset="marker.textOffset"
					:position="marker.position"
				/>
			</template>
		</el-amap>
		<div class="zoom">
			<i class="el-icon-zoom-in" @click="changeZoom('plus')"></i>
			<i class="el-icon-zoom-out" @click="changeZoom('reduce')"></i>
		</div>
	</div>
</template>


<script>
import { AMapManager } from 'vue-amap';
const amapManager = new AMapManager();
export default {
	props: {
		// 是否展示搜索框
		isShowSearch: {
			type: Boolean,
			default: false,
		},
		// 是否展示折线
		isShowPolyline: {
			type: Boolean,
			default: false,
		},
		// 是否多选点位
		isMultiSelect: {
			type: Boolean,
			default: false,
		},
		// 是否展示文本内容
		isShowText: {
			type: Boolean,
			default: false,
		},
		// 地图高度
		mapHeight: {
			type: Number,
			default: 220,
		},
		// 是否禁用
		disabled: {
			type: Boolean,
			default: false,
		},
		pointsMap: {
			type: Array,
			default: () => [],
		},
		//
		centerPoint: {
			type: Array,
			default: () => [],
		},
		addressText: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			center: [104.051571, 30.717714],
			markers: [],
			resultAddress: '',
			// 地图搜索对象
			searchOption: {
				city: '全国',
				citylimit: true,
			},
			zoom: 18,
			pointMarker: [],
			amapManager,
			events: {
				click: (e) => {
					let geocoder = new AMap.Geocoder({
						radius: 1000,
						extensions: 'all',
					});
					let obj = {
						lng: e.lnglat.lng,
						lat: e.lnglat.lat,
					};
					// 获取准确的地址
					geocoder.getAddress([e.lnglat.lng, e.lnglat.lat], (status, result) => {
						if (status === 'complete' && result.info === 'OK' && !this.disabled) {
							if (result && result.regeocode) {
								this.address = result.regeocode.formattedAddress;
								this.addresscon = this.addresscon + result.regeocode.formattedAddress + '、';
								this.resultAddress = result.regeocode.formattedAddress;
								if (this.isMultiSelect) {
									//多选
									if (this.pointMarker.length < 10) {
										let path = [e.lnglat.lng, e.lnglat.lat];
										//绘制散点v
										this.pointMarker.push(obj);
										this.point();
										//绘制折线
										this.polyline.path.push(path);
										// 新增点位配置
										this.$emit('newPoint', obj);
									}
								} else {
									//绘制散点v
									this.pointMarker = [];
									this.pointMarker.push(obj);
									this.point();
									obj.address = this.resultAddress;
									obj.addressData = result.regeocode.addressComponent;
									this.$emit('newPosition', obj);
								}
							}
						}
					});
				},
				zoomchange: (e, i) => {
					console.log(e, i, this.$refs.map);
					this.zoom = this.$refs.map.$$getInstance().getZoom();
				},
			},
			polyline: {
				path: [
					// [104.051571, 30.717714], //具体地址坐标
					// [104.052934, 30.718285], //具体地址坐标
				],
			},
		};
	},
	watch: {
		centerPoint: {
			handler(val) {
				if (val[0] == 0 && val[1] == 0) {
					this.center = [104.051571, 30.717714];
					this.pointMarker = [];
				} else {
					if (val.length == 0) return;
					this.center = val;
					this.pointMarker = [{ lng: val[0], lat: val[1], text: this.addressText }];
				}
			},
			deep: true,
			immediate: true,
		},
	},
	methods: {
		// 改变大小
		changeZoom(type) {
			if (type == 'plus' && this.zoom != 18) {
				this.zoom += 1;
			} else if (type == 'reduce' && this.zoom != 3) {
				this.zoom -= 1;
			}
		},
		// 重置
		reset() {
			this.pointMarker = [];
			this.polyline.path = [];
			this.markers = [];
			this.center = [104.051571, 30.717714];
		},
		// 地图搜索回调
		onSearchResult(pois) {
			const _this = this;
			_this.markers = [];
			let latSum = 0;
			let lngSum = 0;
			if (pois.length > 0) {
				pois.forEach((poi, index) => {
					const { lng, lat } = poi;
					if (index === 0) {
						lngSum = lng;
						latSum = lat;
						const obj = {
							position: [poi.lng, poi.lat],
							text: poi.name,
							offset: [0, 30],
						};
						_this.markers.push(obj);
						_this.pointMarker = [{ lng: poi.lng, lat: poi.lat, text: poi.name }];
					}
				});
				_this.center = [lngSum, latSum];
				this.point();
			}
		},
		//绘制散点
		point() {
			let icon = [
				require('@/assets/icon/1.png'),
				require('@/assets/icon/2.png'),
				require('@/assets/icon/3.png'),
				require('@/assets/icon/4.png'),
				require('@/assets/icon/5.png'),
				require('@/assets/icon/6.png'),
				require('@/assets/icon/7.png'),
				require('@/assets/icon/8.png'),
				require('@/assets/icon/9.png'),
				require('@/assets/icon/10.png'),
			];
			let markers = [];
			this.pointMarker.forEach((item, index) => {
				markers.push({
					position: [item.lng, item.lat],
					icon: this.isMultiSelect ? icon[index] : '', //不设置默认蓝色水滴
					text: item.text ? item.text : this.resultAddress,
					offset: this.isMultiSelect ? [-19, -55] : [-10, -34],
					textOffset: [-2, -45],
					events: {
						click() {},
					},
				});
			});
			//添加点标注
			this.markers = markers;
		},
		//删除点位
		delPoint(index) {
			this.pointMarker.splice(index, 1);
			this.polyline.path.splice(index, 1);
			this.point();
		},
	},
	mounted() {
		this.point();
	},
};
</script>
<style lang="scss" scoped>
.amap-page-container {
	position: relative;
	.zoom {
		position: absolute;
		right: 10px;
		display: flex;
		flex-direction: column;
		bottom: 50px;
		height: 100px;
		justify-content: space-between;
		font-size: 25px;
		background-color: rgba(255, 255, 255, 0.9);
		padding: 15px 5px;
		border-radius: 50px;
		box-shadow: 1px 0 0 0 #c9c8c8;
		color: #999;
		i:hover {
			color: var(--prev-color-primary);
			cursor: pointer;
		}
	}
}
</style>
